import { useEffect, useState } from "react";
import { Button, Column, GMap, WeeklyCalendar } from "react-rainbow-components";
import APIHandler from "../../handler/APIHandler";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "./calendar.scss";
import { Col, Row } from "reactstrap";
import MapComponent from "./MapComponent";
import "moment/locale/de";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CalendarEvent from "./CalendarEvent";
import CustomEventWrapper from "./CalendarEventWrapper";
import { Table } from "react-rainbow-components";
import LeadNotes from "../../components/ui/LeadsNotes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css";
import AddCustomerModal from "../customers/AddCustomerModal";
import WinkelModal from "../../components/ui/modal/winkelModal";
import LeadDeniedModal from "../customers/LeadDeniedModal";
import CreateEventModal from "../customers/CreateEventModal";

/*
let googleRouteWaypoints = [
  { location: { lat: , lng: 2.3522 } },
  { location: { lat: 48.8566, lng: 2.3522 } },
];
*/
const DragAndDropCalendar = withDragAndDrop(Calendar);
moment.locale("de");
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const Calender = () => {
  const [events, setEvents] = useState(null);
  const [allEvents, setAllEvents] = useState([]);
  const [availableClosers, setAvailableClosers] = useState([]);
  const [shownWaypoints, setShownWaypoints] = useState([]); // [ { lat: 0, lng: 0 }, { lat: 0, lng: 0 }
  const [leadData, setLeadData] = useState([]);

  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
  const [showLeadDeniedModal, setShowLeadDeniedModal] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);

  const lightenColor = (color, percent) => {
    let num = parseInt(color.replace("#", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;
    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1)
    );
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = event.user.system_farbe;

    let style = {
      backgroundColor: backgroundColor,
      borderRadius: "4px",
      opacity: 0.8,
      fontSize: "14px",
      color: "black",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user == null) {
      window.location.href = "/login";
    }

    const loadEvents = async () => {
      if (events) {
        return;
      }

      let eventData = await APIHandler.Calendar.getCloserCalendar();

      let calendarData = [];

      eventData.forEach((closer) => {
        calendarData = calendarData.concat(
          closer.closerObj.events.map((event) => {
            setAvailableClosers((prev) => {
              if (prev.includes(closer.closerObj.user)) {
                return prev;
              }

              return prev.concat(closer.closerObj.user);
            });

            return {
              title: event.summary ? event.summary : "Kein Titel",
              start: new Date(event.start.dateTime),
              end: new Date(event.end.dateTime),
              type: "event",
              user: closer.closerObj.user,
              event_id: event.id,
              description: event.description,
            };
          })
        );

        calendarData = calendarData.concat(
          closer.closerObj.slotEvents.map((slot) => {
            return {
              title: slot.summary ? slot.summary : "Kein Titel",
              start: new Date(slot.start.dateTime),
              end: new Date(slot.end.dateTime),
              type: "slot",
              user: closer.closerObj.user,
              event_id: slot.id,
            };
          })
        );
      });

      console.log(calendarData);
      setEvents(calendarData);
      setAllEvents(calendarData);

      APIHandler.Customers.getAllCustomers().then((data) => {
        let leadEntries = data.filter((lead) => lead.status === "LEAD");
        setLeadData(leadEntries);

        let waypoints = [];
        leadEntries.forEach((lead) => {
          console.log(lead);
          if (lead.latitude == null || lead.longitude == null) {
            return;
          }
          waypoints.push({
            lead: lead,
            lat: lead.latitude,
            lng: lead.longitude,
          });
        });

        console.log(waypoints);

        setShownWaypoints(waypoints);
      });
    };

    loadEvents();
  }, []);

  const updateLeads = () => {
    APIHandler.Customers.getAllCustomers().then((data) => {
      let leadEntries = data.filter((lead) => lead.status === "LEAD");
      setLeadData(leadEntries);

      let waypoints = [];
      leadEntries.forEach((lead) => {
        console.log(lead);
        if (lead.latitude == null || lead.longitude == null) {
          return;
        }
        waypoints.push({
          lead: lead,
          lat: lead.latitude,
          lng: lead.longitude,
        });
      });

      setShownWaypoints(waypoints);
    });
  };

  const filterEvents = () => {
    let showEvents = allEvents;

    console.log(availableClosers);

    availableClosers.forEach((closer) => {
      if (closer.showEvents == false) {
        showEvents = showEvents.filter(
          (event) => event.user.id != closer.id || event.type != "event"
        );
      }

      if (closer.showSlots == false) {
        showEvents = showEvents.filter(
          (event) => event.user.id != closer.id || event.type != "slot"
        );
      }
    });

    console.log(showEvents);
    setEvents(showEvents);
  };

  return (
    <div>
      <ToastContainer />
      <h1 className="page-title">Setting Cockpit</h1>

      {selectedLead && <div className="backdrop" />}

      <Row style={{marginBottom:"15px"}}>
        <Col lg="3">
          <CloserViewControl
            closers={availableClosers}
            changeFilter={(closer, type, checked) => {
              let closers = availableClosers;

              let newCloser = closers.find((c) => c.id == closer.id);
              if (type == "slots") {
                newCloser.showSlots = checked;
              } else {
                newCloser.showEvents = checked;
              }
              setAvailableClosers(closers);

              filterEvents();
            }}
          />
        </Col>

        <Col lg="9">
          <MapComponent shownWaypoints={shownWaypoints} />
        </Col>
      </Row>
      <Row>
        <Col lg="3">
          <div className="lead-container">
            <Row>
              <div className="leads-header">
                <h3>Leads</h3>
                <button
                  className="button"
                  onClick={() => setShowNewCustomerModal(true)}
                >
                  Neuer Lead
                </button>
              </div>
            </Row>
            <div className="lead-entries">
              {leadData.map((lead) => {
                return (
                  <LeadEntry
                    onNe={() => {
                      APIHandler.Customers.addHistorie({
                        kunden_id: lead.id,
                        typ: 2,
                        mitarbeiter_id: 1,
                        info: "NE",
                        ne_count: lead.ne + 1,
                      }).then((data) => {
                        console.log(data);

                        if (data.success == false && data.tooManyNE) {
                          toast.error(
                            "Zu viele NEs für diesen Lead, Lead abgelehnt..."
                          );
                          return;
                        }
                      });

                      // Update lead data

                      let newLeadData = leadData.map((leadEntry) => {
                        if (leadEntry.id == lead.id) {
                          leadEntry.ne += 1;
                        }

                        if (leadEntry.ne >= 10) {
                          leadEntry.status = "ABGELEHNT";
                          return leadEntry;
                        }

                        return leadEntry;
                      });

                      setLeadData(newLeadData);
                      updateLeads();
                    }}
                    onAccept={() => {
                      console.log("Selected lead", lead);
                      if (selectedLead != null && selectedLead.id == lead.id) {
                        setSelectedLead(null);
                        return;
                      }

                      setSelectedLead(lead);
                    }}
                    onDeny={() => {
                      setSelectedLead(lead);
                      setShowLeadDeniedModal(true);
                    }}
                    selected={selectedLead?.id == lead.id ? true : false}
                    lead={lead}
                  />
                );
              })}
            </div>
          </div>
        </Col>
        <Col lg="9">
          <DragAndDropCalendar
            events={events ? events : []}
            localizer={localizer}
            startAccessor="start"
            culture="de"
            endAccessor="end"
            min={new Date(1970, 1, 1, 5, 0, 0)} // Start time 06:00 AM
            max={new Date(1970, 1, 1, 22, 0, 0)} // End time 10:00 PM
            view="week"
            views={["week"]}
            onSelectEvent={(event) => {
              if (event.type == "slot") {
                console.log("Selected slot", event);
                setSelectedSlot(event);

                if (selectedLead) {
                  setShowCreateEventModal(true);
                }
              } else {
                console.log("Selected event", event);
              }
            }}
            components={{
              event: CalendarEvent,
            }}
            eventPropGetter={eventStyleGetter}
          />
        </Col>
      </Row>
      <AddCustomerModal
        showModal={showNewCustomerModal}
        onClose={(newEntry) => {
          setShowNewCustomerModal(false);

          if (newEntry) {
            updateLeads();
          }
        }}
      />

      <LeadDeniedModal
        showModal={showLeadDeniedModal}
        onClose={() => {
          setSelectedLead(null);
          setShowLeadDeniedModal(false);
        }}
        onDeny={(lead) => {
          console.log("Deny lead", lead);
          setLeadData(leadData.filter((leadEntry) => leadEntry.id != lead.id));
        }}
        lead={selectedLead}
      />

      <CreateEventModal
        showModal={showCreateEventModal}
        onClose={() => setShowCreateEventModal(false)}
        lead={selectedLead}
        slot={selectedSlot}
      />
    </div>
  );
};

const LeadEntry = ({ lead, selected, onAccept, onDeny, onNe }) => {
  const [showNotes, setShowNotes] = useState(true);

  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={selected ? "lead-entry selected" : "lead-entry"}
      onClick={(e) => {
        e.stopPropagation();
        setExpanded(!expanded);
      }}
    >
      <h5 style={{ marginBottom: 0 }} className="d-flex gap-2">
        <i title={lead.tel} class="ri-phone-fill"></i> {lead.nachname}
        <div className="button-container">
          <div
            title={lead.ne + "x2 NE"}
            className="button ne"
            onClick={(e) => {
              e.stopPropagation();
              onNe();
            }}
          >
            {lead.ne}
          </div>

          <div
            title="Annehmen"
            onClick={(e) => {
              e.stopPropagation();
              onAccept();
            }}
            className="button check"
          >
            <i class="ri-check-line"></i>
          </div>

          <div
            title="Ablehnen"
            onClick={(e) => {
              e.stopPropagation();
              onDeny();
            }}
            className="button denied"
          >
            <i class="ri-prohibited-line"></i>
          </div>
        </div>
      </h5>

      {expanded && (
        <>
          <span style={{ marginTop: "5px" }}>
            <i class="ri-map-pin-line"></i> {lead.straße} {lead.hausnummer},{" "}
            {lead.plz} {lead.ort}
          </span>
          <span style={{ marginTop: "5px" }}>
            <i class="ri-calendar-2-line"></i>{" "}
            {moment(lead.lead_eingangs_datum).format("DD.MM.YYYY")} Facebook
          </span>

          <LeadNotes lead={lead} />
        </>
      )}
    </div>
  );
};

const CloserViewControl = ({ closers, changeFilter }) => {
  const [selectedCloser, setSelectedCloser] = useState(null);

  let closerUI = closers.map((closer) => {
    const style = {
      backgroundColor: closer.system_farbe,
    };

    return (
      <>
        <div style={style} className="closer-profile">
          <div className="closer-filter">
            <img
              alt="people"
              className="closer-avatar"
              src={
                "https://api.dicebear.com/8.x/pixel-art/svg?seed=" +
                closer.email
              }
            />
            <span>{closer.kuerzel}</span>
          </div>
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              defaultChecked={true}
              onChange={(e) => changeFilter(closer, "slots", e.target.checked)}
            />
            <input
              type="checkbox"
              defaultChecked={true}
              onChange={(e) => changeFilter(closer, "events", e.target.checked)}
            />
          </div>
        </div>
      </>
    );
  });

  return (
    <div className="closer-filter-wrapper">
      <h3>Alle Closer</h3>
      <div className="titles">
        <h5>Slots</h5>
        <h5>Events</h5>
      </div>
      {closerUI}
    </div>
  );
};

export default Calender;
