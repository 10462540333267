import React, { useEffect } from "react";

import Routers from "../routes/Routers";
import Header from "../components/header";
import Footer from "../components/footer";
const Layout = ({ hasPermission }) => {
  return (
    <>
      <Header hasPermission={(permission) => hasPermission(permission)} />
      <div>
        <Routers />
      </div>
      <Footer />
    </>
  );
};

export default Layout;
