import { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Input,
  TimePicker,
  DatePicker,
  Column,
  Table,
  Lookup,
  Textarea,
  MultiSelect,
  Picklist,
  Option,
  GoogleAddressLookup,
  CheckboxToggle,
  Badge,
  HelpText,
} from "react-rainbow-components";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import APIHandler from "../../handler/APIHandler";
import { Col, Row } from "reactstrap";
import { getDistance } from "geolib";
import WinkelModal from "../../components/ui/modal/winkelModal";
import MapComponent from "../calender/MapComponent";
import { toast } from "react-toastify";
import moment from "moment";

const styles = {
  input: {
    marginTop: 24,
  },
  inputColumn: {
    width: "100%",
    marginTop: 24,
  },
};

const winkel_address = {
  lat: 51.500972,
  lng: 7.570575,
  icon: "https://www.winkelenergie.de/images/master-1-favicon.ico?crc=4068355874",
};

const AddCustomerModal = ({ showModal, onClose }) => {
  const [customerData, setCustomerData] = useState({
    vorname: "",
    nachname: "",
    email: "",
    tel: "",
    ort: "",
    plz: "",
    strasse: "",
    hausnummer: "",
    wahrscheinliche_umsatzgroesse: "",
    leadSource: "",
    latitude: "",
    longitude: "",
    lead_date: "",
  });

  const [anrede, setAnrede] = useState("");

  const [leadSources, setAvailableLeadSources] = useState([]);

  const [shownWaypoints, setShownWaypoints] = useState([winkel_address]);

  const [distance, setDistance] = useState(null);
  const [airDistance, setAirDistance] = useState(null);

  useEffect(() => {
    if (leadSources.length > 0) return;

    APIHandler.Leads.getAllSources().then((data) => {
      setAvailableLeadSources(data);
    });
  }, []);

  const validateCustomerData = () => {
    if (
      customerData.nachname == "" ||
      customerData.anrede == "" ||
      customerData.leadSource == "" ||
      customerData.lead_date == ""
    ) {
      return false;
    }

    return true;
  };

  const geoCodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        address: address,
        componentRestrictions: {
          country: "DE",
        },
      },
      (results, status) => {
        if (!results) {
          return;
        }

        results = results.filter((result) => {
          return result.address_components.some((component) => {
            return component.short_name.includes("DE");
          });
        });

        if (results.length == 0) return;

        if (status === "OK") {
          console.log(results);

          setCustomerData((prev) => ({
            ...prev,
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          }));

          setShownWaypoints([
            {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            },
            winkel_address,
          ]);
        } else {
          console.log(
            "Geocode was not successful for the following reason: " + status
          );
        }
      }
    );
  };

  const calculateDistances = () => {
    if (shownWaypoints.length < 2) return;
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [shownWaypoints[0]],
        destinations: [shownWaypoints[1]],
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status === "OK") {
          const result = response.rows[0].elements[0];
          setDistance(result.distance.text);
          // Hier können Sie die Entfernung anzeigen oder für weitere Berechnungen verwenden
        } else {
          console.error("Error was: " + status);
        }
      }
    );
  };

  const calculateAirDistances = () => {
    let totalDistance = 0;
    for (let i = 0; i < shownWaypoints.length - 1; i++) {
      const distance = getDistance(
        { latitude: shownWaypoints[i].lat, longitude: shownWaypoints[i].lng },
        {
          latitude: shownWaypoints[i + 1].lat,
          longitude: shownWaypoints[i + 1].lng,
        }
      );
      totalDistance += distance;
    }
    setAirDistance(totalDistance);
  };

  useEffect(() => {
    calculateDistances();
    calculateAirDistances();
  }, [shownWaypoints]);

  useEffect(() => {
    if (
      customerData.plz ||
      customerData.ort ||
      (customerData.strasse && customerData.hausnummer)
    ) {
      if (customerData.plz.length < 4) return;

      let address = `${customerData.plz} ${customerData.ort} ${customerData.strasse} ${customerData.hausnummer}`;

      console.log(address);
      geoCodeAddress(address);
    }
  }, [
    customerData.plz,
    customerData.ort,
    customerData.strasse,
    customerData.hausnummer,
  ]);

  return (
    <WinkelModal
      secure={false}
      show={showModal}
      title="Lead hinzufügen"
      regex={null}
      submitIcon="ri-file-add-line"
      submitText="Lead anlegen"
      onClose={onClose}
      onSubmit={() => {
        customerData.leadQuelle = customerData.leadSource.name;
        customerData.anrede = anrede.name;

        if (!validateCustomerData()) {
          toast.error("Bitte füllen Sie alle Felder aus.");
          return;
        }

        customerData.lead_eingangs_datum = moment(
          customerData.lead_date
        ).format("YYYY-MM-DD");

        if (!customerData.latitude || !customerData.longitude) {
          customerData.latitude = "NULL";
          customerData.longitude = "NULL";
        }

        console.log(customerData);

        APIHandler.Customers.addCustomer(customerData).then((data) => {
          if (data.success) {
            toast.success(data.message);
            onClose(true);
          } else {
            toast.error(data.message);
          }
        });
      }}
      children={
        <>
          <Row>
            <Col>
              <Row>
                <Col md={8}>
                  <Picklist
                    label="Lead-Quelle"
                    style={styles.inputColumn}
                    placeholder="Wähle die Lead-Quelle aus"
                    onChange={(value) => {
                      setCustomerData((prev) => ({
                        ...prev,
                        leadSource: value,
                      }));
                    }}
                    required={true}
                    value={customerData.leadSource}
                    borderRadius="semi-square"
                  >
                    {leadSources
                      .filter((source) => source.aktiv == 1)
                      .map((source) => (
                        <Option
                          name={source.lead_quellen_id}
                          label={source.bezeichnung}
                        />
                      ))}
                  </Picklist>
                </Col>
                <Col
                  md={4}
                  className="d-flex"
                  style={{ alignItems: "flex-end" }}
                >
                  <DatePicker
                    required
                    style={styles.inputColumn}
                    value={customerData.lead_date}
                    maxDate={new Date()}
                    label={
                      <span>
                        Eingangsdatum{" "}
                        <HelpText
                          title="Eingangsdatum"
                          iconSize="small"
                          text={
                            <p>
                              Das Datum, an dem der Lead von extern eingegangen
                              ist.
                            </p>
                          }
                        />
                      </span>
                    }
                    borderRadius="semi-square"
                    onChange={(value) => {
                      setCustomerData((prev) => ({
                        ...prev,
                        lead_date: value,
                      }));
                    }}
                  />
                  <i
                    title="Heute"
                    style={{
                      marginBottom: "6px",
                      marginLeft: "10px",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                    class="ri-calendar-event-line"
                    onClick={() => {
                      setCustomerData((prev) => ({
                        ...prev,
                        lead_date: new Date(),
                      }));
                    }}
                  ></i>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Picklist
                    label="Anrede"
                    style={styles.inputColumn}
                    required={true}
                    placeholder="Wähle die Anrede aus"
                    onChange={(value) => setAnrede(value)}
                    value={anrede}
                    borderRadius="semi-square"
                  >
                    <Option name="HERR" label="Herr" />
                    <Option name="FRAU" label="Frau" />
                    <Option name="FAMILIE" label="Familie" />
                  </Picklist>
                </Col>

                {anrede && anrede.name != "FAMILIE" && (
                  <Col md={4}>
                    <Input
                      label="Vorname"
                      placeholder="Gib den Vornamen ein"
                      type="text"
                      className="rainbow-p-around_medium"
                      style={styles.inputColumn}
                      onChange={(e) =>
                        setCustomerData((prev) => ({
                          ...prev,
                          vorname: e.target.value,
                        }))
                      }
                      value={customerData.vorname}
                      borderRadius="semi-square"
                    />
                  </Col>
                )}

                <Col md={5}>
                  <Input
                    label="Nachname"
                    placeholder="Gib den Nachnamen ein"
                    type="text"
                    required={true}
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        nachname: e.target.value,
                      }))
                    }
                    value={customerData.nachname}
                    borderRadius="semi-square"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Input
                    label="Email"
                    placeholder="Gib die Email-Adresse ein"
                    type="text"
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    value={customerData.email}
                    borderRadius="semi-square"
                  />
                </Col>

                <Col md={6}>
                  <Input
                    label="Telefon"
                    placeholder="Gib die Telefonnummer ein"
                    type="text"
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        tel: e.target.value,
                      }))
                    }
                    value={customerData.tel}
                    borderRadius="semi-square"
                  />
                </Col>
              </Row>

              <Row>
                <Col md={5}>
                  <Input
                    label="Straße"
                    placeholder="Gib die Straße ein"
                    type="text"
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    borderRadius="semi-square"
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        strasse: e.target.value,
                      }))
                    }
                    value={customerData.strasse}
                  />
                </Col>

                <Col md={2}>
                  <Input
                    label="Nr."
                    placeholder="zb. 65"
                    type="text"
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    borderRadius="semi-square"
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        hausnummer: e.target.value,
                      }))
                    }
                    value={customerData.hausnummer}
                  />
                </Col>

                <Col md={2}>
                  <Input
                    label="Postleitzahl"
                    placeholder="PLZ"
                    type="text"
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    borderRadius="semi-square"
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        plz: e.target.value,
                      }))
                    }
                    value={customerData.plz}
                  />
                </Col>
                <Col md={3}>
                  <Input
                    label="Ort"
                    placeholder="Gib den Ort ein"
                    type="text"
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    borderRadius="semi-square"
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        ort: e.target.value,
                      }))
                    }
                    value={customerData.ort}
                  />
                </Col>
              </Row>
            </Col>

            <Col md={12} style={{ marginTop: "30px" }}>
              <MapComponent shownWaypoints={shownWaypoints} />
            </Col>
            {distance && (
              <Row>
                <Col md={4} style={{ marginTop: "30px" }}>
                  <h5 className="d-flex gap-2">
                    <i class="ri-car-line"></i>Route: {distance}
                  </h5>
                </Col>
                <Col md={4} style={{ marginTop: "30px" }}>
                  <h5 className="d-flex gap-2">
                    <i class="ri-flight-takeoff-line"></i>Luftlinie:{" "}
                    {(airDistance / 1000).toFixed(2)} km{" "}
                  </h5>
                </Col>
              </Row>
            )}
          </Row>
        </>
      }
    />
  );
};

export default AddCustomerModal;
