import { useEffect, useState } from "react";
import { Button, Input } from "react-rainbow-components";
import APIHandler from "../../handler/APIHandler";

const Home = () => {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    APIHandler.Auth.getProfile().then((data) => {
      setProfile(data.user);
    });
  }, []);

  return (
    <div>
      <h1>Home</h1>
      {profile ? (
        <div>
          <p>Name: {profile.vorname}</p>
          <p>Email: {profile.email}</p>

          <Button
            label="Abmelden"
            variant="destructive"
            className="rainbow-m-around_medium"
            onClick={() => {
              APIHandler.Auth.logout();
              window.location.href = "/login";
            }}
          />
        </div>
      ) : (
        <p>Du bist nicht angemeldet</p>
      )}
    </div>
  );
};
export default Home;
