import { useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Input,
  TimePicker,
  DatePicker,
  Column,
  Table,
  Lookup,
  Textarea,
  MultiSelect,
  Picklist,
  Option,
  GoogleAddressLookup,
  CheckboxToggle,
  Badge,
  HelpText,
} from "react-rainbow-components";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import APIHandler from "../../handler/APIHandler";
import { Col, Row } from "reactstrap";
import { getDistance } from "geolib";
import WinkelModal from "../../components/ui/modal/winkelModal";
import MapComponent from "../calender/MapComponent";
import { toast } from "react-toastify";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { icon } from "@fortawesome/fontawesome-svg-core";

const styles = {
  input: {
    marginTop: 24,
  },
  inputColumn: {
    width: "100%",
    marginTop: 24,
  },
};

const containerStyles = {
  width: "100%",
};

const IconStyles = {
  height: 24,
  width: 24,
  backgroundColor: "#01b6f5",
  borderRadius: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
};

const CustomerSearchModal = ({ showModal, onClose, onSelect }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState(null);

  const [allCustomers, setAllCustomers] = useState(null);

  const search = (value) => {
    setIsLoading(true);

    let customers = allCustomers.filter((customer) => {
      return (
        customer.label?.toLowerCase().includes(value.toLowerCase()) ||
        customer.value.hero_project_id?.includes(value)
      );
    });

    setOptions(customers);

    setIsLoading(false);
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      setIsLoading(true);
      let customers = await APIHandler.Customers.getAllCustomers();
      customers = customers.map((customer) => {
        return {
          label:
            customer.vorname +
            " " +
            customer.nachname +
            " - " +
            customer.straße +
            " " +
            customer.hausnummer,
          value: customer,

          icon: (
            <span style={{ fontWeight: "300" }}>
              {customer.hero_project_id}
            </span>
          ),
        };
      });

      setOptions(customers);
      setAllCustomers(customers);
      setIsLoading(false);
    };

    fetchCustomers();
  }, []);

  return (
    <WinkelModal
      secure={false}
      show={showModal}
      title="Kundensuche"
      regex={null}
      submitIcon="ri-arrow-right-s-line"
      submitText="Fortfahren"
      onClose={onClose}
      onSubmit={() => onSelect(selected.value)}
      children={
        <>
          <Row>
            <Col md="12">
              <Lookup
                id="lookup-3"
                placeholder="Kunden suchen"
                disabled={isLoading}
                size="medium"
                options={options}
                value={selected}
                onChange={(option) => setSelected(option)}
                isLoading={isLoading}
                onSearch={search}
                style={containerStyles}
                borderRadius="semi-rounded"
                emptyTitle="Keine Kunden gefunden"
                emptyDescription="Es wurden keine Kunden gefunden"
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};

export default CustomerSearchModal;
