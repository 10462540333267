import React, { useEffect, useState } from "react";

import {
  Modal,
  Button,
  Input,
  TimePicker,
  DatePicker,
  Column,
  Table,
  Lookup,
  Textarea,
  MultiSelect,
  Picklist,
  Option,
  GoogleAddressLookup,
  CheckboxToggle,
  Badge,
} from "react-rainbow-components";

import APIHandler from "../../handler/APIHandler";
import { useParams, useSearchParams } from "react-router-dom";
import AddCustomerModal from "./AddCustomerModal";
import WinkelModal from "../../components/ui/modal/winkelModal";

const Customers = () => {
  const [customers, setCustomers] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [userData, setUserData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [view, setView] = useState("settingboard");

  const [texts, setTexts] = useState({
    title: "",
    new_button: "",
  });

  const [availableLeadSources, setAvailableLeadSources] = useState(null);

  useEffect(() => {
    APIHandler.Leads.getAllSources().then((data) => {
      setAvailableLeadSources(data);
    });

    APIHandler.Customers.getAllCustomers().then((data) => {
      setCustomers(data);
    });

    APIHandler.Auth.getProfile().then((data) => {
      setUserData(data.user);
    });
  }, []);

  useEffect(() => {
    console.log(searchParams.get("filter"));

    if (searchParams.get("filter") == "settingboard") {
      setTexts({
        title: "Settingboard",
        new_button: "Lead",
      });
      setView("settingboard");
    } else if (searchParams.get("filter") == "closerdokument") {
      setTexts({
        title: "Closer Dokument",
        new_button: "",
      });
      setView("closerdokument");
    } else {
      setTexts({
        title: "Kunden",
        new_button: "Kunde",
      });
      setView("customer");
    }
  }, searchParams);

  if (!availableLeadSources || !customers) return null;

  const filteredCustomers = () => {
    if (!userData) {
      return [];
    }

    let customerdata = customers.map((customer) => {
      return {
        ...customer,
        lead_quellen_id:
          availableLeadSources.find(
            (source) => source.lead_quellen_id == customer.lead_quellen_id
          )?.bezeichnung || "N/A",
      };
    });
    if (view == "settingboard") {
      return customerdata.filter((customer) => customer.status == "LEAD");
    }

    if (view == "closerdokument") {
      return customerdata.filter(
        (customer) =>
          customer.status == "INTERESSENT" && customer.closer_id == userData.id
      );
    }
  };

  const badgeStyles = { color: "#1de9b6", marginLeft: "0.5rem" };

  const StatusBadge = ({ value }) => (
    <Badge label={value} variant="lightest" style={badgeStyles} />
  );

  return (
    <div>
      <h1>{texts.title}</h1>

      {view == "settingboard" && (
        <Button
          label={"+ Neuer " + texts.new_button}
          variant="brand"
          className="rainbow-m-around_medium"
          onClick={() => setShowModal(true)}
          borderRadius="semi-square"
        />
      )}

      <Table
        emptyTitle={"Keine Daten gefunden.."}
        emptyDescription={"Es wurden keine Daten gefunden"}
        isLoading={!userData}
        data={filteredCustomers()}
        keyField="id"
      >
        <Column header="ID" field="id" />
        <Column header="Vorname" field="vorname" />
        <Column header="Name" field="nachname" />
        <Column header="Email" field="email" />
        <Column header="Telefon" field="tel" />
        <Column header="Ort" field="ort" />
        <Column header="PLZ" field="plz" />
        <Column header="Straße" field="straße" />
        <Column header="Hausnummer" field="hausnummer" />
        <Column header="Whrs. Umsatz" field="wahrscheinliche_umsatzgroesse" />
        <Column header="Lead-Quelle" field="lead_quellen_id" />
      </Table>

      <WinkelModal />
    </div>
  );
};

export default Customers;
