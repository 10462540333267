import { useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Input,
  TimePicker,
  DatePicker,
  Column,
  Table,
  Lookup,
  Textarea,
  MultiSelect,
  Picklist,
  Option,
  GoogleAddressLookup,
  CheckboxToggle,
  Badge,
  HelpText,
  DateTimePicker,
  ButtonGroupPicker,
  ButtonOption,
} from "react-rainbow-components";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import APIHandler from "../../handler/APIHandler";
import { Col, Row } from "reactstrap";
import { getDistance } from "geolib";
import WinkelModal from "../../components/ui/modal/winkelModal";
import MapComponent from "../calender/MapComponent";
import { toast } from "react-toastify";
import moment from "moment";

const styles = {
  input: {
    marginTop: 24,
  },
  inputColumn: {
    width: "100%",
    marginTop: 24,
  },
};

const CreateCustomerEventModal = ({
  showModal,
  onClose,
  closer,
  start,
  end,
  customer,
  slot,
  terminart,
  terminort,
}) => {
  const [zoom, setZoom] = useState(8);
  const [eventType, setEventType] = useState(null);
  useEffect(() => {
    console.log("Type", eventType);
  }, [eventType]);

  const bookEvent = () => {
    APIHandler.Calendar.bookEvent({
      terminart: terminart.id,
      terminort: terminort.toUpperCase(),
      closer: closer,
      customer: customer,
      start: slot.start,
      slot: slot,
    }).then((data) => {
      toast.success("Termin erfolgreich gebucht");
      onClose();
    });
  };

  if (!slot || !customer) return null;

  return (
    <WinkelModal
      secure={false}
      show={showModal}
      title={terminart.bezeichnung + " Termin anlegen"}
      regex={null}
      submitIcon="ri-calendar-check-line"
      submitText="Termin buchen & Bestätigung senden"
      onClose={onClose}
      onSubmit={() => {
        bookEvent();
      }}
      children={
        <>
          <Row>
            <Col md={12}>
              <MapComponent
                shownWaypoints={[
                  {
                    lat: customer.latitude,
                    lng: customer.longitude,
                  },
                ]}
                defZoom={zoom}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "18px" }}>
            <h4>{terminart.bezeichnung} Termin</h4>
            <Col md={6}>
              <span className="d-flex gap-2">
                <i class="ri-id-card-line"></i>
                {customer?.anrede} {customer?.vorname} {customer?.nachname}
              </span>
              <span className="d-flex gap-2">
                <i class="ri-phone-line"></i>
                {customer?.tel}
              </span>
              <span className="d-flex gap-2">
                <i class="ri-mail-line"></i>
                {customer?.email}
              </span>
              <span className="d-flex gap-2">
                <i class="ri-map-pin-line"></i>
                {customer?.straße} {customer?.hausnummer}, {customer?.plz}{" "}
                {customer?.ort}
              </span>
            </Col>

            <Col md={6}>
              <span className="d-flex gap-2">
                <img
                  alt="people"
                  class="closer-avatar"
                  style={{ width: "20px", height: "20px" }}
                  src="https://api.dicebear.com/8.x/pixel-art/svg?seed=ralf52735@gmail.com"
                />
                {closer.vorname} {closer.nachname}
              </span>{" "}
              <input
                type="datetime-local"
                value={moment(slot?.start).format("YYYY-MM-DDTHH:mm")}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};

export default CreateCustomerEventModal;
