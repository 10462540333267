import { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Input,
  TimePicker,
  DatePicker,
  Column,
  Table,
  Lookup,
  Textarea,
  MultiSelect,
  Picklist,
  Option,
  GoogleAddressLookup,
  CheckboxToggle,
  Badge,
  HelpText,
} from "react-rainbow-components";
import APIHandler from "../../handler/APIHandler";
import { Col, Row } from "reactstrap";
import { getDistance } from "geolib";
import WinkelModal from "../../components/ui/modal/winkelModal";
import { toast } from "react-toastify";
import moment from "moment";
import EnumHandler from "../../handler/EnumHandler";
import AddressAutocomplete from "../../components/ui/address/AddressLookup";

const styles = {
  input: {
    marginTop: 24,
  },
  inputColumn: {
    width: "100%",
    marginTop: 24,
  },
};

const winkel_address = {
  lat: 51.500972,
  lng: 7.570575,
  icon: "https://www.winkelenergie.de/images/master-1-favicon.ico?crc=4068355874",
};

const AddGuestModal = ({
  showModal,
  onClose,
  closer,
  start,
  end,
  customer,
  slot,
  terminart,
  terminort,
}) => {
  const [customerData, setCustomerData] = useState({
    vorname: "",
    nachname: "",
    email: "",
    tel: "",
    ort: "",
    plz: "",
    strasse: "",
    hausnummer: "",
    wahrscheinliche_umsatzgroesse: "",
    leadSource: "",
    latitude: "",
    longitude: "",
    lead_date: "",
  });

  const [anrede, setAnrede] = useState("");

  const [leadSources, setAvailableLeadSources] = useState([]);

  const [shownWaypoints, setShownWaypoints] = useState([winkel_address]);

  const [distance, setDistance] = useState(null);
  const [airDistance, setAirDistance] = useState(null);

  useEffect(() => {
    if (leadSources.length > 0) return;

    APIHandler.Leads.getAllSources().then((data) => {
      setAvailableLeadSources(data);
    });
  }, []);

  const validateCustomerData = () => {
    if (customerData.nachname == "" || customerData.anrede == "") {
      return false;
    }

    return true;
  };

  const geoCodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        address: address,
        componentRestrictions: {
          country: "DE",
        },
      },
      (results, status) => {
        if (!results) {
          return;
        }

        results = results.filter((result) => {
          return result.address_components.some((component) => {
            return component.short_name.includes("DE");
          });
        });

        if (results.length == 0) return;

        if (status === "OK") {
          console.log(results);

          setCustomerData((prev) => ({
            ...prev,
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          }));

          setShownWaypoints([
            {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            },
            winkel_address,
          ]);
        } else {
          console.log(
            "Geocode was not successful for the following reason: " + status
          );
        }
      }
    );
  };

  const calculateDistances = () => {
    if (shownWaypoints.length < 2) return;
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [shownWaypoints[0]],
        destinations: [shownWaypoints[1]],
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status === "OK") {
          const result = response.rows[0].elements[0];
          setDistance(result.distance.text);
          // Hier können Sie die Entfernung anzeigen oder für weitere Berechnungen verwenden
        } else {
          console.error("Error was: " + status);
        }
      }
    );
  };

  const calculateAirDistances = () => {
    let totalDistance = 0;
    for (let i = 0; i < shownWaypoints.length - 1; i++) {
      const distance = getDistance(
        { latitude: shownWaypoints[i].lat, longitude: shownWaypoints[i].lng },
        {
          latitude: shownWaypoints[i + 1].lat,
          longitude: shownWaypoints[i + 1].lng,
        }
      );
      totalDistance += distance;
    }
    setAirDistance(totalDistance);
  };

  useEffect(() => {
    calculateDistances();
    calculateAirDistances();
  }, [shownWaypoints]);

  useEffect(() => {
    if (
      customerData.plz ||
      customerData.ort ||
      (customerData.strasse && customerData.hausnummer)
    ) {
      if (customerData.plz.length < 4) return;

      let address = `${customerData.plz} ${customerData.ort} ${customerData.strasse} ${customerData.hausnummer}`;

      console.log(address);
      geoCodeAddress(address);
    }
  }, [
    customerData.plz,
    customerData.ort,
    customerData.strasse,
    customerData.hausnummer,
  ]);

  const bookEvent = (customer) => {
    APIHandler.Calendar.bookEvent({
      terminart: terminart.id,
      terminort: terminort.toUpperCase(),
      closer: closer,
      customer: customer,
      start: slot.start,
      slot: slot,
    }).then((data) => {
      toast.success("Termin erfolgreich gebucht");
      onClose();
    });
  };

  return (
    <WinkelModal
      secure={false}
      show={showModal}
      title="Gast Termin anlegen"
      regex={null}
      submitIcon="ri-file-add-line"
      submitText="Gast Termin anlegen"
      onClose={onClose}
      onSubmit={() => {
        customerData.leadQuelle = customerData.leadSource.name;
        customerData.anrede = anrede.name;

        if (!validateCustomerData()) {
          toast.error("Bitte füllen Sie alle Felder aus.");
          return;
        }

        customerData.lead_eingangs_datum = moment(
          customerData.lead_date
        ).format("YYYY-MM-DD");

        if (!customerData.latitude || !customerData.longitude) {
          customerData.latitude = "NULL";
          customerData.longitude = "NULL";
        }

        console.log(customerData);

        APIHandler.Customers.addGuest(customerData).then((data) => {
          if (data.success) {
            customerData.id = data.customer.insertId;

            bookEvent(customerData);
          } else {
            toast.error(data.message);
          }
        });
      }}
      children={
        <>
          <Row>
            <Row>
              <Col md={6}>
                <span className="d-flex gap-2">
                  <img
                    alt="people"
                    class="closer-avatar"
                    style={{ width: "20px", height: "20px" }}
                    src="https://api.dicebear.com/8.x/pixel-art/svg?seed=ralf52735@gmail.com"
                  />
                  {closer?.vorname} {closer?.nachname}
                </span>{" "}
                <input
                  type="datetime-local"
                  value={moment(slot?.start).format("YYYY-MM-DDTHH:mm")}
                />
              </Col>
              <Col md={6}>
                <span className="d-flex gap-2">
                  {terminart?.bezeichnung} Termin
                </span>
                <span className="d-flex gap-2">
                  {EnumHandler.mediumToString(terminort)} Termin
                </span>
              </Col>
            </Row>
            <Col>
              <Row>
                <Col md={3}>
                  <Picklist
                    label="Anrede"
                    style={styles.inputColumn}
                    required={true}
                    placeholder="Wähle die Anrede aus"
                    onChange={(value) => setAnrede(value)}
                    value={anrede}
                    borderRadius="semi-square"
                  >
                    <Option name="HERR" label="Herr" />
                    <Option name="FRAU" label="Frau" />
                    <Option name="FAMILIE" label="Familie" />
                  </Picklist>
                </Col>

                {anrede && anrede.name != "FAMILIE" && (
                  <Col md={4}>
                    <Input
                      label="Vorname"
                      placeholder="Gib den Vornamen ein"
                      type="text"
                      className="rainbow-p-around_medium"
                      style={styles.inputColumn}
                      onChange={(e) =>
                        setCustomerData((prev) => ({
                          ...prev,
                          vorname: e.target.value,
                        }))
                      }
                      value={customerData.vorname}
                      borderRadius="semi-square"
                    />
                  </Col>
                )}

                <Col md={5}>
                  <Input
                    label="Nachname"
                    placeholder="Gib den Nachnamen ein"
                    type="text"
                    required={true}
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        nachname: e.target.value,
                      }))
                    }
                    value={customerData.nachname}
                    borderRadius="semi-square"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Input
                    label="Email"
                    placeholder="Gib die Email-Adresse ein"
                    type="text"
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    value={customerData.email}
                    borderRadius="semi-square"
                  />
                </Col>

                <Col md={6}>
                  <Input
                    label="Telefon"
                    placeholder="Gib die Telefonnummer ein"
                    type="text"
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        tel: e.target.value,
                      }))
                    }
                    value={customerData.tel}
                    borderRadius="semi-square"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <AddressAutocomplete
                    onAddressSelect={(address) => {
                      let addressString = address.description;
                      let addressArray = addressString.split(",");

                      let strassenArray = addressArray[0].split(" ");
                      let ortArray = addressArray[1].split(" ");

                      console.log(strassenArray);
                      console.log(ortArray);

                      let hausnummer = strassenArray[1];

                      let strasse = "";
                      //look through addressArray search for hausnummer
                      strassenArray.forEach((element) => {
                        if (
                          typeof parseInt(element) === "number" &&
                          !isNaN(parseInt(element))
                        ) {
                          hausnummer = element;
                        } else {
                          strasse += element + " ";
                        }
                      });
                      let ort = "";
                      let plz = "";

                      ortArray.forEach((element) => {
                        if (
                          typeof parseInt(element) === "number" &&
                          !isNaN(parseInt(element))
                        ) {
                          plz = element;
                        } else {
                          ort += element + " ";
                        }
                      });

                      console.log(ort);

                      setCustomerData((prev) => ({
                        ...prev,
                        strasse: strasse,
                        hausnummer: hausnummer,
                        plz: plz,
                        ort: ort,
                      }));
                      setShownWaypoints([address, winkel_address]);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={5}>
                  <Input
                    label="Straße"
                    placeholder="Gib die Straße ein"
                    type="text"
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    borderRadius="semi-square"
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        strasse: e.target.value,
                      }))
                    }
                    value={customerData.strasse}
                  />
                </Col>

                <Col md={2}>
                  <Input
                    label="Nr."
                    placeholder="zb. 65"
                    type="text"
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    borderRadius="semi-square"
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        hausnummer: e.target.value,
                      }))
                    }
                    value={customerData.hausnummer}
                  />
                </Col>

                <Col md={2}>
                  <Input
                    label="Postleitzahl"
                    placeholder="PLZ"
                    type="text"
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    borderRadius="semi-square"
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        plz: e.target.value,
                      }))
                    }
                    value={customerData.plz}
                  />
                </Col>
                <Col md={3}>
                  <Input
                    label="Ort"
                    placeholder="Gib den Ort ein"
                    type="text"
                    className="rainbow-p-around_medium"
                    style={styles.inputColumn}
                    borderRadius="semi-square"
                    onChange={(e) =>
                      setCustomerData((prev) => ({
                        ...prev,
                        ort: e.target.value,
                      }))
                    }
                    value={customerData.ort}
                  />
                </Col>
              </Row>
            </Col>
            {distance && (
              <Row>
                <Col md={4} style={{ marginTop: "30px" }}>
                  <h5 className="d-flex gap-2">
                    <i class="ri-car-line"></i>Route: {distance}
                  </h5>
                </Col>
                <Col md={4} style={{ marginTop: "30px" }}>
                  <h5 className="d-flex gap-2">
                    <i class="ri-flight-takeoff-line"></i>Luftlinie:{" "}
                    {(airDistance / 1000).toFixed(2)} km{" "}
                  </h5>
                </Col>
              </Row>
            )}
          </Row>
        </>
      }
    />
  );
};

export default AddGuestModal;
