import { useEffect, useRef, useState } from "react";
import { Column, Table } from "react-rainbow-components";

import "./addresslookup.scss";

import { useJsApiLoader } from "@react-google-maps/api";

const libraries = ["places"];

const AddressAutocomplete = ({ onAddressSelect }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const inputRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCUjPiR-uJ7soEgWCm4IphXDVCXURXhQP8", // Ersetze durch deinen API-Schlüssel
    libraries,
  });

  useEffect(() => {
    if (isLoaded && !autocomplete) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["address"],
          componentRestrictions: { country: "de" }, // Optional: Beschränke auf ein Land
        }
      );

      autoComplete.addListener("place_changed", () => {
        console.log("Place changed");
        const place = autoComplete.getPlace();
        if (place.geometry) {
          const address = {
            description: place.formatted_address,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          onAddressSelect(address);
        }
      });

      autoComplete.addListener("");

      setAutocomplete(autoComplete);
    }
  }, [isLoaded, autocomplete, onAddressSelect]);

  return (
    <div className="address-autocomplete-container">
      <label for="address-autocomplete" className="address-autocomplete-label">
        Adresse
      </label>

      <input
        ref={inputRef}
        id="address-autocomplete"
        className="form-control address-autocomplete"
        type="text"
        placeholder="📍 Suche nach einer Adresse"
      />
    </div>
  );
};
export default AddressAutocomplete;
