import { useCallback, useEffect, useState } from "react";
import APIHandler from "../../handler/APIHandler";
import { Col, Container, Row } from "reactstrap";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { ToastContainer } from "react-toastify";
import { useParams, useSearchParams } from "react-router-dom";
import "./customer.scss";
import {
  ActivityTimeline,
  Card,
  Input,
  TimelineMarker,
} from "react-rainbow-components";
import { MapComponent } from "../calender/MapComponent.1";

const DragAndDropCalendar = withDragAndDrop(Calendar);
moment.locale("de");
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const Customer = () => {
  const [customerData, setCustomerData] = useState(null);
  const [historieTypen, setHistorieTypen] = useState(null);
  const [terminArten, setTerminArten] = useState(null);
  const [closerData, setCloserData] = useState(null);
  const [zoom, setZoom] = useState(8);

  const [shownWaypoints, setShownWaypoints] = useState([]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    APIHandler.Customers.getCustomerById(searchParams.get("id")).then(
      (data) => {
        //order by zeitpunkt
        let historie = data.historie.sort((a, b) => {
          return new Date(b.zeitpunkt) - new Date(a.zeitpunkt);
        });

        setCustomerData(data);
        setZoom(20);
      }
    );

    APIHandler.Customers.getHistorieTypen().then((data) => {
      setHistorieTypen(data);
    });

    APIHandler.Calendar.getAvailableEventTypes().then((data) => {
      setTerminArten(data);
    });
  }, [searchParams]);

  useEffect(() => {
    APIHandler.User.getUserById(customerData?.closer_id).then((data) => {
      setCloserData(data[0]);
    });
  }, [customerData]);

  console.log(terminArten);

  return (
    <div className="customer">
      <ToastContainer />

      <Row>
        <Col lg="4">
          <h4 className="mt-4">Kunden Historie</h4>
          <ActivityTimeline>
            {customerData &&
              historieTypen &&
              customerData.historie.map((entry, index) => {
                return (
                  <TimelineMarker
                    key={index}
                    icon={
                      <i
                        className={
                          historieTypen.find(
                            (historien_typ) =>
                              historien_typ.historien_typ_id === entry.typ
                          ).icon + " timeline-icon"
                        }
                      ></i>
                    }
                    label={
                      <div className="historie-entry">
                        <span className="d-flex gap-2">
                          <img
                            src={
                              "https://api.dicebear.com/8.x/pixel-art/svg?seed=ralf52735@gmail.com"
                            }
                            alt="people"
                            className="closer-avatar"
                          />
                          {closerData && closerData.kuerzel}
                        </span>
                        <span>
                          {entry.typ == 2 &&
                            entry.ne_count +
                              "" +
                              historieTypen.find(
                                (historien_typ) =>
                                  historien_typ.historien_typ_id === entry.typ
                              ).titel}{" "}
                        </span>
                      </div>
                    }
                    description={
                      <div>
                        {(entry.typ == 4 || entry.typ == 3) && (
                          <p>{entry.info}</p>
                        )}
                        {entry.typ == 5 && (
                          <div>
                            <span>
                              {
                                terminArten.find(
                                  (terminart) =>
                                    terminart.id === entry.termin?.terminart_id
                                )?.bezeichnung
                              }{" "}
                              <span>{entry.termin?.medium} </span>
                              Termin{" "}
                            </span>

                            <span> angelegt</span>
                          </div>
                        )}

                        <span className="text-gray">
                          {new Date(entry.zeitpunkt).toLocaleDateString() +
                            " " +
                            moment(entry.zeitpunkt).format("HH:mm")}
                        </span>
                      </div>
                    }
                  />
                );
              })}
          </ActivityTimeline>
        </Col>

        <Col lg="8">
          <Row>
            <h4 className="mt-4">Maps View</h4>
            <MapComponent
              shownWaypoints={[
                {
                  lat: customerData && customerData.latitude,
                  lng: customerData && customerData.longitude,
                },
              ]}
              defZoom={zoom}
            />
          </Row>
          <Row>
            <Col lg="6">
              <Row>
                <h4 className="mt-4">Kunden Daten</h4>
                <Col lg="12">
                  <p className="d-flex gap-2">
                    <i className="ri-mail-line"></i>
                    <a href={"mailto:" + (customerData && customerData.email)}>
                      {customerData && customerData.email}
                    </a>
                  </p>
                  <p className="d-flex gap-2">
                    <i className="ri-phone-line"></i>
                    <a href={"tel:" + (customerData && customerData.tel)}>
                      {customerData && customerData.tel}
                    </a>
                  </p>
                  <p className="d-flex gap-2">
                    <i className="ri-user-line"></i>
                    {customerData && customerData.anrede}{" "}
                    {customerData && customerData.vorname}{" "}
                    {customerData && customerData.nachname}
                  </p>
                  <p className="d-flex gap-2">
                    <i className="ri-map-pin-line"></i>
                    {customerData &&
                      customerData.strasse +
                        " " +
                        customerData.hausnummer +
                        ", " +
                        customerData.plz +
                        " " +
                        customerData.ort}
                  </p>
                  <p className="d-flex gap-2">
                    <img
                      style={{ width: "28px" }}
                      src="https://images.basaas.com/uploads/app/61f908db6603190011200987/19235b6e-0350-4e32-a7eb-1ad97a70cc77-smart-business-cloud-footer-hero.png?width=250"
                    />
                    <a
                      href={
                        "https://login.hero-software.de/partner/Projects/view/" +
                        customerData?.hero_intern_project_id
                      }
                      target="_blank"
                    >
                      {customerData && customerData.hero_project_id}{" "}
                    </a>
                  </p>
                </Col>
              </Row>
            </Col>

            <Col lg="6">
              <Row>
                <h4 className="mt-4">Verantwortlicher Closer</h4>
                <Col lg="12">
                  {closerData ? (
                    <>
                      <p className="d-flex gap-2">
                        <img
                          src={
                            "https://api.dicebear.com/8.x/pixel-art/svg?seed=ralf52735@gmail.com"
                          }
                          alt="people"
                          className="closer-avatar"
                        />
                        {closerData && closerData.vorname}{" "}
                        {closerData && closerData.nachname}
                      </p>
                      <p className="d-flex gap-2">
                        <i className="ri-mail-line"></i>
                        <a href={"mailto:" + (closerData && closerData.email)}>
                          {closerData && closerData.email}
                        </a>
                      </p>
                    </>
                  ) : (
                    <p className="text-gray">Kein Closer zugewiesen</p>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Customer;
