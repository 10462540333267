import { useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Input,
  TimePicker,
  DatePicker,
  Column,
  Table,
  Lookup,
  Textarea,
  MultiSelect,
  Picklist,
  Option,
  GoogleAddressLookup,
  CheckboxToggle,
  Badge,
  HelpText,
} from "react-rainbow-components";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import APIHandler from "../../handler/APIHandler";
import { Col, Row } from "reactstrap";
import { getDistance } from "geolib";
import WinkelModal from "../../components/ui/modal/winkelModal";
import MapComponent from "../calender/MapComponent";
import { toast } from "react-toastify";
import moment from "moment";

const styles = {
  input: {
    marginTop: 24,
  },
  inputColumn: {
    width: "100%",
    marginTop: 24,
  },
};

const reasons = [
  {
    id: 1,
    title: "Telefonnummer nicht korrekt (NE-Mail schicken)",
  },
  {
    id: 2,
    title: "Dach zu klein (weniger als 5kWp / 12 Module)",
  },
  {
    id: 3,
    title: "Kein ernsthaftes Interesse/ Kein Commitment",
  },
  {
    id: 4,
    title: "Möchte kein Zoom-Meeting machen",
  },
  {
    id: 5,
    title:
      "Zu wenig Stromverbrauch (weniger als 2500kWh + Wirtschaftlichkeit ist ihm wichtig)",
  },
  {
    id: 6,
    title: "Kein Eigentümer der Immobilie",
  },
  {
    id: 7,
    title: "Zu weit entfernt (<50km)",
  },
  {
    id: 8,
    title:
      "Passt nicht zu unserer Positionierung (will das billigste Angebot/ nur Komponenten/ Teilleistung)",
  },
  {
    id: 9,
    title: "Hat sich schon für einen anderen Anbieter entschieden",
  },
  {
    id: 10,
    title: "Möchte eine Mietanlage",
  },
  {
    id: 11,
    title: "MFH mit mehr als 2 Anlagen bedarf",
  },
  {
    id: 12,
    title: "10x2x NE",
  },
  {
    id: 13,
    title: "Lead schon durch andere Quelle eingegangen",
  },
  {
    id: 14,
    title: "Falscher Zeitpunkt",
  },
  {
    id: 15,
    title: "Spam",
  },
];

const LeadDeniedModal = ({ showModal, onClose, lead, onDeny }) => {
  useEffect(() => {
    if (!showModal) return;
    if (!reasonList.current) return;

    reasonList.current.focus();
    //emulate click
    setTimeout(() => {
      reasonList.current.click();
    }, 300);
  }, [showModal]);

  const reasonList = useRef(null);

  const [reason, setReason] = useState(null);

  console.log(lead);
  return (
    <WinkelModal
      secure={false}
      show={showModal}
      title="Lead ablehnen"
      regex={null}
      submitIcon="ri-spam-3-line"
      submitText="Lead ablehnen"
      onClose={onClose}
      onSubmit={() => {
        console.log("Submit");

        if (!reason) {
          toast.error("Bitte wählen Sie einen Ablehnungsgrund aus");
          return;
        }

        APIHandler.Leads.denyLead(lead.id, reason.label).then((data) => {
          console.log(data);

          if (data.success) {
            toast.success("Lead erfolgreich abgelehnt");
            onDeny(lead);

            setReason(null);
            onClose();
          } else {
            toast.error("Fehler beim Ablehnen des Leads");
          }
        });
      }}
      children={
        <>
          <Row>
            <Col md={4}>
              <span className="d-flex gap-2">
                <i class="ri-id-card-line"></i>
                {lead?.nachname}
              </span>
            </Col>
            <Col md={4}>
              <span className="d-flex gap-2">
                <i class="ri-phone-line"></i>
                {lead?.tel}
              </span>
            </Col>
            <Col md={4}>
              <span className="d-flex gap-2">
                <i class="ri-mail-line"></i>
                {lead?.email}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Picklist
                ref={reasonList}
                label="Ablehnungsgrund"
                style={styles.inputColumn}
                placeholder="Wählen Sie einen Ablehnungsgrund aus"
                onChange={(value) => {
                  setReason(value);
                }}
                required={true}
                value={reason}
                borderRadius="semi-square"
              >
                {reasons.map((reason) => (
                  <Option
                    key={reason.id}
                    name={reason.title}
                    label={reason.title}
                  />
                ))}
              </Picklist>
            </Col>
          </Row>
        </>
      }
    />
  );
};

export default LeadDeniedModal;
