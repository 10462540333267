import { useState } from "react";
import { Button, Input } from "react-rainbow-components";
import APIHandler from "../../handler/APIHandler";
import { useGoogleLogin } from "@react-oauth/google";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faCheck,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Container } from "reactstrap";
const Login = () => {
  const [user, setUser] = useState(null);

  const inputStyles = {
    width: 300,
  };

  const handleLogin = (data) => {
    setUser(data.user);
    localStorage.setItem("sessionToken", data.sessionToken);
    localStorage.setItem("user", JSON.stringify(data.user));
  };

  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      let googleData = await APIHandler.Auth.sendGoogleToken(code);

      console.log(googleData);

      handleLogin(googleData);
    },
    flow: "auth-code",
    scope: "profile email https://www.googleapis.com/auth/calendar",
  });

  return (
    <Container>
      <h1>Login</h1>

      <h2>Winkel Solarenergie</h2>

      <Button
        onClick={login}
        variant="brand"
        className="rainbow-m-around_medium d-flex gap-2"
        borderRadius="semi-rounded"
      >
        Mit Google anmelden{" "}
        <FontAwesomeIcon
          icon={faArrowRight}
          className="rainbow-m-left_medium"
        />
      </Button>
      {user && (
        <div>
          <h2>Logged in as {user.email}</h2>
          <Button
            onClick={() => {
              setUser(null);
              localStorage.removeItem("user");
              localStorage.removeItem("sessionToken");
            }}
            label="Logout"
            icon={<FontAwesomeIcon icon={faCheck} />}
          />
        </div>
      )}
    </Container>
  );
};
export default Login;
