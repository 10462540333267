import logo from "./logo.svg";
import "./App.css";
import Layout from "./layout/Layout";
import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import APIHandler from "./handler/APIHandler";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
function App() {
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    const getPermissions = async () => {
      if (localStorage.getItem("permissions")) {
        setPermissions(JSON.parse(localStorage.getItem("permissions")));
        return;
      }

      console.log("Fetching permissions");
      const permissions = await APIHandler.Permissions.getUserPermissions();

      console.log("Permissions fetched: ", permissions);
      setPermissions(permissions);

      //save permissions to local storage
      localStorage.setItem("permissions", JSON.stringify(permissions));
    };

    if (!permissions) {
      getPermissions();
    }
  }, []);

  const hasPermission = (permission) => {
    if (!permissions) {
      return false;
    }

    return permissions.includes(permission);
  };

  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId="16188662263-r95t9ce1ob8tuea3d3odge3fr716p84d.apps.googleusercontent.com">
        {" "}
        <Layout hasPermission={(permission) => hasPermission(permission)} />
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

export default App;
