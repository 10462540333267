import { useEffect, useRef, useState } from "react";
import { Column, Table } from "react-rainbow-components";
import APIHandler from "../../handler/APIHandler";

import { useJsApiLoader } from "@react-google-maps/api";
import AddressAutocomplete from "../../components/ui/address/AddressLookup";

const libraries = ["places"];

const Test = () => {
  useEffect(() => {}, []);

  return (
    <div>
      <h2>Google Maps Suche</h2>

      <AddressAutocomplete
        onAddressSelect={(address) => console.log(address)}
      />
    </div>
  );
};
export default Test;
