import React from "react";
import { Polyline } from "@react-google-maps/api";
import { MapComponent } from "./MapComponent.1";

export const containerStyle = {
  width: "100%",
  height: "400px",
};

export const center = {
  lat: 51.9607,
  lng: 7.6261,
};

export default React.memo(MapComponent);
