import { useEffect, useState } from "react";
import "./header.scss";
import APIHandler from "../handler/APIHandler";

const Header = ({ hasPermission }) => {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    setProfile(JSON.parse(localStorage.getItem("user")));
  }, []);

  console.log("Profile: ", profile);

  function logout() {
    APIHandler.Auth.logout();
    setProfile(null);
  }

  return (
    <header>
      <h1>Winkel Solarenergie</h1>

      <nav>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          {hasPermission(2) && (
            <li>
              <a href="/kunden?filter=settingboard">Settingboard</a>
            </li>
          )}
          {hasPermission(3) && (
            <li>
              <a href="/kunden?filter=closerdokument">Closer Dokument</a>
            </li>
          )}
          {hasPermission(5) && (
            <li>
              <a href="/kunden">Kunden</a>
            </li>
          )}
          <li>
            <a href="/settingcockpit">Settingcockpit</a>
          </li>
          <li>
            <a href="/event-booking">Termin buchen</a>
          </li>
        </ul>
      </nav>

      {profile ? (
        <div className="profile-area">
          <img
            src={
              "https://api.dicebear.com/8.x/pixel-art/svg?seed=" + profile.email
            }
            alt="Profilbild"
          />
          <div className="profile-info">
            <p>{profile.vorname}</p>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => logout()}
              href="/login"
            >
              Abmelden
            </a>
          </div>
        </div>
      ) : (
        <div className="profile-area">
          <img
            src={"https://api.dicebear.com/8.x/pixel-art/svg?seed=0"}
            alt="Profilbild"
          />
          <div className="profile-info">
            <a style={{ cursor: "pointer" }} href="/login">
              Anmelden
            </a>
          </div>
        </div>
      )}
    </header>
  );
};
export default Header;
