import { useEffect, useState } from "react";
import "./leadnotes.scss";
import APIHandler from "../../handler/APIHandler";

const LeadNotes = ({ lead }) => {
  const [showNotes, setShowNotes] = useState(false);

  return (
    <div className="lead-notes">
      <div className="lead-notes-header">
        <div className="notes">
          {lead.historie
            .filter((historie_entry) => historie_entry.typ == 3)
            .map((note) => (
              <Note note={note} />
            ))}
        </div>
        <div className="add-note">
          <button
            className="send-note"
            onClick={() => setShowNotes(!showNotes)}
          >
            <i class="ri-send-plane-line"></i> Notiz hinzufügen
          </button>
        </div>
      </div>
    </div>
  );
};

const Note = ({ note }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    APIHandler.User.getUserById(note.mitarbeiter).then((data) => {
      setUserData(data[0]);
    });
  }, note);

  console.log(userData);

  const getTimeDiferenceString = (date) => {
    let now = new Date();
    let sentAt = new Date(date);

    let diff = now - sentAt;

    let seconds = Math.floor(diff / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    if (days > 0) {
      return days + " Tagen";
    } else if (hours > 0) {
      return hours + " Stunden";
    } else if (minutes > 0) {
      return minutes + " Minuten";
    } else {
      return seconds + " Sekunden";
    }

    return "vor " + seconds + " Sekunden";
  };

  return (
    <span className="note">
      <img
        src={
          "https://api.dicebear.com/8.x/pixel-art/svg?seed=" + userData?.email
        }
        alt="note"
      />
      {userData?.kuerzel}: {note.info}
      <span
        title={new Date(note.zeitpunkt).toLocaleString()}
        className="sent-at"
      >
        {" "}
        vor {getTimeDiferenceString(note.zeitpunkt)}
      </span>
    </span>
  );
};

export default LeadNotes;
