import React from "react";

const CalendarEvent = ({ event }) => {
  switch (event.type) {
    case "event":
      return (
        <div className="event">
          <span className="title">{event.title}</span>
        </div>
      );
    case "slot":
      return (
        <div className="slot">
          <span className="title">{event.title}</span>
        </div>
      );
    default:
      return <span>saddas</span>;
  }
};

export default CalendarEvent;
