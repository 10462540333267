import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import Customers from "../pages/customers/Customers";
import Products from "../pages/products/Products";
import Login from "../pages/access/Login";
import Profile from "../pages/access/Profile";
import Calender from "../pages/calender/Calender";
import Home from "../pages/home/Home";
import EventBooking from "../pages/event-booking/EventBooking";
import Customer from "../pages/customers/Customer";
import Test from "../pages/test/test";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/kunden" element={<Customers />} />
      <Route path="/produkte" element={<Products />} />
      <Route path="/login" element={<Login />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/settingcockpit" element={<Calender />} />
      <Route path="/event-booking" element={<EventBooking />} />
      <Route path="/customer" element={<Customer />} />
      <Route path="/test" element={<Test />} />
    </Routes>
  );
};

export default Routers;
