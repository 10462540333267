import { useEffect, useState } from "react";
import { Column, Table } from "react-rainbow-components";
import APIHandler from "../../handler/APIHandler";

const Products = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    APIHandler.Products.getAllProducts().then((data) => {
      setProducts(data);
      console.log(data);
    });
  }, []);

  return (
    <div>
      <h1>Produkte</h1>
      <Table data={products} keyField="id">
        <Column header="ID" field="id" />
        <Column header="Name" field="name" />
      </Table>
    </div>
  );
};
export default Products;
