import React from "react";

export default class EnumHandler extends React.Component {
  static mediumToString(medium) {
    console.log("Medium", medium);

    if (medium === null) {
      return "Unbekannt";
    }
    switch (medium) {
      case "VOR_ORT":
        return "Vor Ort";
      case "VIDEO_CALL":
        return "Video Call";
      case "TELEFON":
        return "Telefon";
      default:
        return "Unbekannt";
    }
  }
}
