import { useCallback, useEffect, useState } from "react";
import {
  ButtonGroupPicker,
  ButtonOption,
  Column,
  Table,
} from "react-rainbow-components";
import APIHandler from "../../handler/APIHandler";
import { Col, Row } from "reactstrap";
import "./eventbooking.scss";
import WinkelModal from "../../components/ui/modal/winkelModal";
import { WeeklyCalendar, Card } from "react-rainbow-components";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CalendarEvent from "../calender/CalendarEvent";
import CreateCustomerEventModal from "../customers/CreateCustomerEventModal";
import CustomerSearchModal from "../customers/CustomerSearchModal";
import { ToastContainer } from "react-toastify";
import AddCustomerModal from "../customers/AddCustomerModal";
import AddGuestModal from "../customers/AddGuestModal";
import AddressAutocomplete from "../../components/ui/address/AddressLookup";

const DragAndDropCalendar = withDragAndDrop(Calendar);
moment.locale("de");
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const EventBooking = () => {
  const [closer, setCloser] = useState([]);
  const [events, setEvents] = useState(null);
  const [terminarten, setTerminarten] = useState([]);

  const [selectedCloser, setSelectedCloser] = useState(null);
  const [selectedTerminart, setSelectedTerminart] = useState(null);
  const [selectedTerminOrt, setSelectedTerminOrt] = useState(null);

  const [closerEvents, setCloserEvents] = useState([]);

  const [currentWeek, setCurrentWeek] = useState(new Date());

  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const firstDay = new Date();
  firstDay.setDate(firstDay.getDate() - firstDay.getDay());
  const daysOfWeek = Array.from(Array(7), (_value, index) => {
    const day = new Date(firstDay);
    day.setDate(day.getDate() + index);
    return day;
  });

  const handleSelectSlot = (slotInfo) => {
    console.log(slotInfo);

    setSelectedSlot(slotInfo);
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user == null) {
      window.location.href = "/login";
    }

    APIHandler.User.getAllUsers().then((data) => {
      data = data.filter((user) => user.funktion_intern === "CLOSER");
      setCloser(data);
    });

    APIHandler.Calendar.getAvailableEventTypes().then((data) => {
      setTerminarten(data);
    });
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      if (selectedCloser && selectedTerminart && selectedTerminOrt) {
        let eventData = await APIHandler.Calendar.getCloserCalendarById(
          selectedCloser.id
        );

        eventData = eventData[0];

        let calendarData = [];

        calendarData = calendarData.concat(
          eventData.closerObj.events.map((event) => {
            return {
              title: event.summary ? event.summary : "Kein Titel",
              start: new Date(event.start.dateTime),
              end: new Date(event.end.dateTime),
              type: "event",
              user: eventData.closerObj.user,
              event_id: event.id,
              description: event.description,
            };
          })
        );

        calendarData = calendarData.concat(
          eventData.closerObj.slotEvents.map((slot) => {
            return {
              title: slot.summary ? slot.summary : "Kein Titel",
              start: new Date(slot.start.dateTime),
              end: new Date(slot.end.dateTime),
              type: "slot",
              user: eventData.closerObj.user,
              event_id: slot.id,
            };
          })
        );

        console.log(calendarData);

        setEvents(calendarData);
      }
    };

    fetchEvents();
  }, [selectedCloser]);

  return (
    <div className="eventbooking">
      <ToastContainer />
      <div>
        <table>
          <thead>
            <tr>
              <th>Closer/Terminart</th>
              {terminarten?.map((terminart) => (
                <th>{terminart.bezeichnung}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {closer.map((closer) => (
              <tr>
                <td>{closer.kuerzel}</td>
                {terminarten.map((terminart) => (
                  <td>
                    <ButtonGroupPicker
                      className="rainbow-m-around_medium"
                      label={
                        terminart.bezeichnung +
                        " - " +
                        closer.vorname +
                        " " +
                        closer.nachname
                      }
                      onChange={(value) => {
                        setSelectedCloser(closer);
                        setSelectedTerminart(terminart);
                        setSelectedTerminOrt(value);
                      }}
                      name="filter"
                      size="medium"
                    >
                      <ButtonOption label="Vor Ort" name="vor_ort" />
                      <ButtonOption label="Vídeo Call" name="video_call" />
                      {terminart.id === 3 && (
                        <ButtonOption label="Telefon" name="telefon" />
                      )}
                    </ButtonGroupPicker>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedCloser && selectedTerminart && selectedTerminOrt && (
        <>
          <h1></h1>
          <Calendar
            events={events ? events : []}
            localizer={localizer}
            startAccessor="start"
            culture="de"
            endAccessor="end"
            min={new Date(1970, 1, 1, 5, 0, 0)} // Start time 06:00 AM
            max={new Date(1970, 1, 1, 22, 0, 0)} // End time 10:00 PM
            onSelectSlot={handleSelectSlot}
            onSelectEvent={(event) => {
              if (event.type === "slot") {
                setSelectedSlot(event);
              }
            }}
            selectable
            view="week"
            views={["week"]}
            components={{
              event: CalendarEvent,
            }}
          />
        </>
      )}

      <CustomerSearchModal
        showModal={selectedSlot && !selectedCustomer}
        onSelect={(customer) => {
          console.log(customer);
          setSelectedCustomer(customer);
        }}
        onClose={() => {
          setSelectedSlot(null);
        }}
      />

      <AddGuestModal
        showModal={selectedSlot && !selectedCustomer}
        slot={selectedSlot}
        customer={selectedCustomer}
        closer={selectedCloser}
        terminart={selectedTerminart}
        terminort={selectedTerminOrt}
        onClose={() => {
          setSelectedSlot(null);
          setSelectedCustomer(null);
        }}
      />

      <CreateCustomerEventModal
        showModal={selectedSlot && selectedCustomer}
        slot={selectedSlot}
        customer={selectedCustomer}
        closer={selectedCloser}
        terminart={selectedTerminart}
        terminort={selectedTerminOrt}
        onClose={() => {
          setSelectedSlot(null);
          setSelectedCustomer(null);
        }}
      />
    </div>
  );
};
export default EventBooking;
