import React, { useEffect } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { getDistance } from "geolib";
import { containerStyle, center } from "./MapComponent";

export function MapComponent({ shownWaypoints, defZoom }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCUjPiR-uJ7soEgWCm4IphXDVCXURXhQP8",
  });

  const [map, setMap] = React.useState(null);
  const [zoom, setZoom] = React.useState(10);

  const [activeMarker, setActiveMarker] = React.useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = React.useState(false);

  const onMarkerClick = (marker) => {
    setActiveMarker(marker);
    setInfoWindowOpen(true);
  };

  const onCloseInfoWindow = () => {
    setActiveMarker(null);
    setInfoWindowOpen(false);
  };

  useEffect(() => {
    if (!map) return;
    const bounds = new window.google.maps.LatLngBounds();
    shownWaypoints.forEach((marker) => {
      bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
    });
    map.fitBounds(bounds, 200);
    window.google.maps.event.addListenerOnce(map, "bounds_changed", () => {
      map.setZoom(zoom);
    });

    console.log("Setting zoom", zoom);
    setMap(map);
  }, [zoom]);

  useEffect(() => {
    if (!map) return;
    const bounds = new window.google.maps.LatLngBounds();
    shownWaypoints.forEach((marker) => {
      bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
    });

    map.fitBounds(bounds);

    window.google.maps.event.addListenerOnce(map, "bounds_changed", () => {
      map.setZoom(zoom);
    });

    console.log("Setting zoom", zoom);
  }, [shownWaypoints]);

  useEffect(() => {
    setZoom(defZoom);
  }, [defZoom]);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    shownWaypoints.forEach((marker) => {
      bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
    });
    map.fitBounds(bounds, 200);
    window.google.maps.event.addListenerOnce(map, "bounds_changed", () => {
      let currentZoom = map.getZoom();
      map.setZoom(currentZoom - 10); // Verringern Sie den Zoom um 1 oder mehr, je nach Bedarf
    });
    setMap(map);
  });

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const calculateDistances = () => {
    let totalDistance = 0;
    for (let i = 0; i < shownWaypoints.length - 1; i++) {
      const distance = getDistance(
        { latitude: shownWaypoints[i].lat, longitude: shownWaypoints[i].lng },
        {
          latitude: shownWaypoints[i + 1].lat,
          longitude: shownWaypoints[i + 1].lng,
        }
      );
      totalDistance += distance;
    }
    return totalDistance;
  };

  const totalDistance = calculateDistances();

  console.log(activeMarker);

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        mapTypeId={"hybrid"}
        onUnmount={onUnmount}
      >
        {shownWaypoints.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={() => onMarkerClick(marker)}
            icon={
              marker.icon
                ? {
                    url: marker.icon,
                    scaledSize: new window.google.maps.Size(30, 30),
                  }
                : null
            }
          >
            {infoWindowOpen && activeMarker === marker && (
              <InfoWindow
                marker={activeMarker}
                visible={infoWindowOpen}
                onClose={onCloseInfoWindow}
              >
                <div>
                  <h4>
                    {activeMarker.lead?.anrede} {activeMarker.lead?.vorname}{" "}
                    {activeMarker.lead?.nachname}
                  </h4>
                  <p>
                    Adresse: {activeMarker.lead?.straße}{" "}
                    {activeMarker.lead?.hausnummer}, {activeMarker.lead?.plz}{" "}
                    {activeMarker.lead?.ort}
                  </p>
                  <p>
                    Telefon:{" "}
                    <a href={`tel:${activeMarker.lead?.tel}`}>
                      {activeMarker.lead?.tel}
                    </a>
                  </p>
                  <p>
                    Email:{" "}
                    <a href={`mailto:${activeMarker.lead?.email}`}>
                      {activeMarker.lead?.email}
                    </a>
                  </p>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}
