import React from "react";

export default class APIHandler extends React.Component {
  //static URL = "http://localhost:4000";

  static URL = "https://api.winkel.zenesa.de";

  static Customers = class {
    static async getAllCustomers() {
      try {
        const response = await fetch(`${APIHandler.URL}/customers`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async getHistorieTypen() {
      try {
        const response = await fetch(
          `${APIHandler.URL}/customers/historie/typen`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async addCustomer(customer) {
      try {
        console.log(customer);
        const response = await fetch(`${APIHandler.URL}/customers`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(customer),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async addGuest(guest) {
      try {
        console.log(guest);
        const response = await fetch(`${APIHandler.URL}/customers/guest`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(guest),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async addHistorie(history) {
      try {
        const response = await fetch(`${APIHandler.URL}/customers/historie`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(history),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async getCustomerById(id) {
      try {
        const response = await fetch(`${APIHandler.URL}/customers/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }
  };

  static Products = class {
    static async getAllProducts() {
      try {
        const response = await fetch(`${APIHandler.URL}/products`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }
  };

  static Auth = class {
    static async login(credentialResponse) {
      try {
        const response = await fetch(`${APIHandler.URL}/auth/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ credentialResponse }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async logout() {
      localStorage.removeItem("sessionToken");
      localStorage.removeItem("user");
    }

    static async getProfile() {
      try {
        const response = await fetch(`${APIHandler.URL}/auth/me`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("sessionToken"),
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async sendGoogleToken(token) {
      try {
        const response = await fetch(`${APIHandler.URL}/auth/google`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }
  };

  static User = class {
    static async getAllUsers() {
      try {
        const response = await fetch(`${APIHandler.URL}/user`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async getUserById(id) {
      try {
        const response = await fetch(`${APIHandler.URL}/user/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }
  };

  static Leads = class {
    static async getAllSources() {
      try {
        const response = await fetch(`${APIHandler.URL}/leads/sources`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async denyLead(id, reason) {
      try {
        const response = await fetch(`${APIHandler.URL}/leads/deny`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("sessionToken"),
          },
          body: JSON.stringify({ id, reason }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async book(lead) {
      try {
        const response = await fetch(`${APIHandler.URL}/leads/book`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("sessionToken"),
          },
          body: JSON.stringify({ lead }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }
  };

  static Permissions = class {
    static async getAllPermissions() {
      try {
        const response = await fetch(`${APIHandler.URL}/permissions`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async getUserPermissions() {
      try {
        const response = await fetch(`${APIHandler.URL}/permissions/user`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("sessionToken"),
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }
  };

  static Calendar = class {
    static async getEvents() {
      try {
        const response = await fetch(
          `${APIHandler.URL}/calendar/google/events`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("sessionToken"),
            },
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async bookEvent(
      terminart_id,
      terminort,
      closer_id,
      customer_id,
      start
    ) {
      try {
        const response = await fetch(`${APIHandler.URL}/calendar/event`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("sessionToken"),
          },
          body: JSON.stringify({
            terminart_id,
            terminort,
            closer_id,
            customer_id,
            start,
          }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async getEventsById(closer_id) {
      try {
        const response = await fetch(
          `${APIHandler.URL}/calendar/google/closer/${closer_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("sessionToken"),
            },
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async createEvent(slot, lead, eventType) {
      try {
        const response = await fetch(
          `${APIHandler.URL}/calendar/google/event`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("sessionToken"),
            },
            body: JSON.stringify({
              slot,
              lead,
              eventType,
            }),
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async deleteSlot(closer_id, slot_id) {
      try {
        const response = await fetch(`${APIHandler.URL}/calendar/google/slot`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("sessionToken"),
          },
          body: JSON.stringify({ closer_id, slot_id }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async getSlots() {
      try {
        const response = await fetch(
          `${APIHandler.URL}/calendar/google/slots`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("sessionToken"),
            },
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async getCloserCalendar() {
      try {
        const response = await fetch(
          `${APIHandler.URL}/calendar/google/closer`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("sessionToken"),
            },
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }
    static async getCloserCalendarById(closerid) {
      try {
        const response = await fetch(
          `${APIHandler.URL}/calendar/google/closer/${closerid}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("sessionToken"),
            },
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }

    static async getAvailableEventTypes() {
      try {
        const response = await fetch(`${APIHandler.URL}/calendar/eventTypes`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("sessionToken"),
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error when fetching the setting: ", error);
      }
    }
  };
}
